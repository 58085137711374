<template>
  <v-container fluid fill-height>
    <v-row justify="center" class="ma-0">
      <v-col cols="12" align="center">
        <v-card elevation="0" class="align-center justify-center" max-width="500">
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" class="text-center">
                <img src="@/assets/logo_color_packhai_subscription.png" width="100%">
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" class="text-center py-4 color-black-navigation">
                <span class="fonttitleform">จำนวนวันใช้บริการได้หมดแล้ว</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn block depressed class="btn-login" @click="gotopackage()">
                  ซื้อแพ็คเกจ
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    methods: {
      gotopackage(){
        this.$router.push('/package')
      },
    }, //methods
  }
</script>
